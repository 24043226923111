.slika-i-tekst {
  display: grid;
  grid-template-columns: 1fr 1fr; /* Dijeli prostor na dvije kolone */
  grid-gap: 20px; /* Dodaj razmak između slike i teksta */
}

.card-img-top {
  max-width: 100%; /* Slika će popuniti širinu dostupnog prostora */
}

.odsjekCard {
  /* Dodajte stilizaciju za tekst ako je potrebno */
}

@font-face {
  font-family: asu-font;
  src: url('./css/font/Source_Sans_Pro/SourceSansPro-Black.ttf');
  font-weight:400;
}
@font-face {
  font-family: asu-font-regular;
  src: url('./css/font/Source_Sans_Pro/SourceSansPro-Regular.ttf');
  /* font-weight:400; */
}
@font-face {
  font-family: asu-font-light;
  /* src: url('./font/Source_Sans_Pro/SourceSansPro-Light.ttf'); */
  src: url('./css/font/Source_Sans_Pro/SourceSansPro-Regular.ttf');
  /* font-weight:400; */
}
/* h1 {
  font-family: asu-font;
} */
h1,h2,h3,h4,h5,h6 {
  font-family: asu-font; 
}
p{
  font-family: asu-font-regular; 
  margin-bottom: 0px;
}
a {
  color: inherit;
  text-decoration: auto;
}
a:hover {
  color: #ca0a47 !important;
}
.active{
  color: #ca0a47 !important;
}

.css-1ex1afd-MuiTableCell-root {
  font-family: "asu-font-light","Helvetica","Arial",sans-serif !important;
  font-size: 0.975rem !important;
  padding: 9px !important;
}
  /* @media upit za prikaz na većem ekranu (laptop) */
  @media screen and (min-width: 1024px) {
    .display-lg {
      display: block; /* Prikaz na većem ekranu */
    }
    .samoNaMobilnoj{
      display: none;
    }
  }
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {

  img.logo {
      width: 80% !important;
  }
  h1.footerNaslov {
      font-size: 20px;
      /* font-size: 2rem !important; */
  }

}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  img.logo {
      width: 80% !important;
  }
  /* h1.footerNaslov {
      font-size: 20px;
  } */
}

/* CSS za sakrivanje navigacije na mobilnim ekranima */
@media (max-width: 768px) {
  .mobile-nav {
    display: none;
  }
  .logoMobilna {
    width: 70% !important;
  }
  button#dropdown-basic {
    box-shadow: none;
    text-transform: uppercase;
  }
  a.nav-link.meni{
    /* font-weight: 900; */
    text-transform: uppercase;
    left: 0;
    color: #000000;
    padding: 0;
    /* margin-left: -10 !important; */
  }
  a.nav-link.active {
  text-transform: uppercase;
  color: rgb(167, 0, 0);
  font-weight: 900;
  }
  
  li.nav-item {
  text-transform: uppercase;
    font-family: 'asu-font-light';
  }
  .dropdown-menu.show {
    width: 100% !important;
    padding: 0px !important;
}
a.nav-link {
  color: black;
  padding: 0;
}
a {
  text-decoration: auto;
}
.col.mb-4 {
  width: 50% !important;
}
.card-body {
  text-align: justify;
  margin-top: 0px !important;
}
.col.treci {
  padding: 0;
}
.d-flex.bd-highlight {
  text-align: justify;
}
.container.drugaSekcija {
  padding-left: 0;
  padding-right: 0;
}
.card-title {
  margin-bottom: 0.5rem;
  text-align: left;
}
.col-md-8 {
  text-align: justify;
}
.container.prvaSekcija {
  padding: 0;
}
.MuiGrid-root.MuiGrid-item.MuiGrid-grid-xs-12.MuiGrid-grid-md-2.noMobilna.css-12xeub0-MuiGrid-root {
  display: none;
}

  
}
/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {/*MAIN MENU*/
  /*Fiksiranje menija meni*/
  header.border-bottom {
      margin-bottom: 0px !important;
      position: sticky;
      z-index: 99999;
      width: 100%;
      background-color: white;
  }
  header.site-header.sticky-top.py-1 {
    padding-top: 0px !important;
}
  .py-3.mb-4.border-bottom {
      margin-bottom: 0px !important;
  }
  nav.navbar.navbar-expand-lg.navbar-light.bg-light {
      width: 100%;
      margin: auto;
  }
  img.navbar-brand-logo {
      width: 400px !important;
  }
  flex.flex-wrap.justify-content-left {
      justify-content: left !important;
  }
  flex.flex-wrap.justify-content-left {
      max-width: 91% !important;
  }
  
  a.navbar-brand {
      text-transform: uppercase;
  }
  a.nav-link.active {
      text-transform: uppercase;
      font-weight: 900;
    /* color: black; */
  }
  
  li.nav-item {
      text-transform: uppercase;
      font-family: 'asu-font-light';
  }
  
  .navbar-light .navbar-nav .nav-link {
      color: rgb(0, 0, 0);
      font-weight: 900;
  }
  
  .py-3 {
      padding-top: 0rem!important;
      padding-bottom: 0rem!important;
  }
  .py-2 {
      padding-top: 0rem!important;
      padding-bottom: 0rem!important;
  }
  
  .justify-content-end {
      justify-content: flex-end!important;
      background-color: #c8c8c8;
      /* width: 400px;
    height: 300px;*/
        background-image: linear-gradient(to left, rgba(0,0,0,.5), rgba(0,0,0,0) 38%, rgba(255,255,255,0) 60%, rgba(255,255,255,.5)), linear-gradient(to right, white 42%, #c8c8c8 49%);
  
  }
  a.nav-link {
      font-size: 15px !important;
      /* font-weight: 900; */
  }
  .nav-link {
      color: #000000;
      /* font-weight: 900; */
  }
  .container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
      margin-top: 2%;
  }
  .dropdown-menu {
      background-color: #ffffffb8 !important;
  }
  
  /*Top meni*/
  a.nav-link.link-dark.px-2 {
      font-size: 12px !important;
      color: black !important;
          padding-top: 11px;
              float: left;
  }
  input.form-control.me-2{
      height: 25px;
      margin-top: 6px;
      padding: 0rem 0rem;
      color: #212529;
      background-color: #939393;
      border: 1px solid #ffffff;
      border-radius: 0;
  }
  span#meni {
      /* position: inherit; */
      width: 10px;
      margin-top: 6px !important;
      display: block;
      float: left;
  }
  header.py-3.mb-4.border-bottom {
      margin-bottom: 0px !important;
  }
  
  /*Sekcija prvi dio Video slajd*/
  /*Slajder*/
  .carousel-item {
      margin-bottom: -5px !important;
      }
  .row>* {
      padding-right: 0px !important;
      padding-left: 0px !important;
  }
  .container.prvaSekcija {
      width: 100% !important;
      max-width: 100%;
      margin-top: 0px;
  }
  /*Prijavi se dugme*/
  button.btn.btn-danger.prijaviSe {
      color: #fff;
      background-color: #c00d0d;
      border-color: #c00d0d;
      min-width: 176px;
      border-radius: 0;
      text-transform: uppercase;
  }
  /*Sekcija drugi dio */
  .container.drugaSekcija {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
          margin-top: 0px;
  }
  
  .row.hoverStyle {
      background: url(/media/images/sekcija22.jpeg) no-repeat 50% 0px / cover ;
      background-size: 70% 935px;
      background-position: left;
      opacity: 0.9;
      /*position: absolute;*/
     /* background-size: 100% 935px;*/
     /* width: 100%;
      height: 600px;*/
    /*  display: block;*/
      /*max-height: 100%;
      max-width: 100% !important;*/
      margin-top: 0px;
      background-origin: padding-box;
  }
  
  .col.drugi {
      content: "";
      /*background: rgba(36, 70, 105, 0.74);*/
     /* position: absolute;*/
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      height: 935px;
       background-image:linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 100%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to right, #ffffff00 21%, #fffffff0 65%) 
  }
  .card.upisFront  {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      width: 50% !important;
      float: right;
      margin-top: 10%;
      height: 65%;
      /* margin-right: 12%; */
      /* margin-top: 10%; */
      /* position: absolute; */
      /* padding: 200px; */
      word-wrap: break-word;
      background-color: #ffffff00;
      background-clip: border-box;
      border: none;
      border-radius: 0.25rem;
  }
  h1.card-title{
      font-family: asu-font;
  }
  /*Sekcija treci dio */
  .container.trecaSekcija {
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
          margin-top: 0px;
  }
  
  .row.hoverStyleTri {
      background: url(/media/images/sekcija3.jpeg) no-repeat 50% 0px / cover ;
      background-size: 100% 935px;
      background-position: right;
      opacity: 0.9;
      /*position: absolute;*/
     /* width: 100%;
      height: 600px;*/
    /*  display: block;*/
   /*   max-height: 100%;
      max-width: 100% !important;*/
      margin-top: 0px;
      background-origin: padding-box;
  }
  .col.treci {
      content: "";
      /*background: rgba(36, 70, 105, 0.74);*/
     /* position: absolute;*/
      top: 0;
      bottom: 0;
      left: 0;
      height: 935px;
      right: 0;
       background-image:linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to left, #ffffff00 46%, #ffffffe0 63%) 
  }
  .card.upisFrontTri {
      position: relative;
      display: flex;
      flex-direction: column;
      min-width: 0;
      width: 36% !important;
      float: left;
      margin-top: 10%;
      height: 65%;
      margin-left: 7%;
      /* margin-top: 10%; */
      /* position: absolute; */
      /* padding: 200px; */
      word-wrap: break-word;
      background-color: #ffffff00;
      background-clip: border-box;
      border: none;
      border-radius: 0.25rem;
  
  }
  /*Footer*/
  .container.footer {
      max-width: 100% !important;
      background-color: #9d9d9c;
      margin: 0px;
      padding-top: 32px;
      /* display: block;
      position: absolute;
      bottom: 0; */
  }
  .col.footer {
      text-align: center;
      margin-top: 40px !important;
  }
  .footerCopy {
      background-color: #2d3134;
      color: aliceblue;
      text-align: center;
      padding: 5px;
  }
  h1.footerNaslov {
      color: white;
      text-align: center;
      font-size: 2rem !important;
  }
  p.footer-info {
      margin-bottom: 0px;
      text-align: center;
      font-family: asu-font-light;
      font-weight: bold !important;
  }
  
  /*Odjeci */
  main.container.odjseciBox {
      margin: 0;
      max-width: 100%;
      padding: 0;
  }
  
  /*card odsjeci*/
  h1.card-title.odjseciTitle {
      text-transform: uppercase;
  }
  
  .card-body.card-body.odsjekCard{
      padding: 2rem 2rem !important;
      background-image: linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 90%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to left, #ffffff00 -14%, #868686b5 57%)
  
  }
  .row.odsjeci {
      margin: 0 !important;
      padding: 0 !important;
      box-sizing: inherit;
  }
  .card.odsjek {
      width: 33.3%;
      border: 0px;;
  }
  
  /*Odjsek detalj*/
  /* .row.hoverStyle.oodsjekDetalj {
      background: url(/media/images/gluma.jpeg) no-repeat 50% 0px / cover;
      background-size: 100% 935px;
      
  } */

  .col.drugi.odsjekDetalj{
  background-image: linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 100%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to right, #ffffff00 21%, #ffffff 65%)
  }
  
  /*Dekanat*/
  .container.drugaSekcija.dekanat {
      background-image: linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 15%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to left, #ffffff00 4%, #97979799 63%)
  }
  .container.dekanat {
      margin-top: 0px;
      padding-top: 20px;
      padding-bottom: 20px;
  }
  
  /*Nastavnik slika*/
  img.card-img-top.nastanikSlika {
      width: 90%;
  }
  .container.drugaSekcija.dekanat.nastavnik {
      padding-top: 80px;
      /* margin-top: 100px; */
      padding-bottom: 80px;
  }}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  /*MAIN MENU*/
/*Fiksiranje menija meni*/
/*Dropdown*/
button#dropdown-basic {
  box-shadow: none;
  text-transform: uppercase;
}

.dropdown-menu.show {
  background-color: #ffffffd9 !important;
}
header.py-3.mb-4.border-bottom {
  margin-bottom: 0px !important;
  position: fixed;
  z-index: 99999;
  width: 100%;
  background-color: white;
}

.py-3.mb-4.border-bottom {
  margin-bottom: 0px !important;
}
nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  width: 100%;
  margin: auto;
}
img.navbar-brand-logo {
  width: 400px !important;
}
flex.flex-wrap.justify-content-left {
justify-content: left !important;
}
flex.flex-wrap.justify-content-left {
  max-width: 91% !important;
}

a.navbar-brand {
  text-transform: uppercase;
}
a.nav-link.meni{
  font-weight: 900;
}
a.nav-link.active {
text-transform: uppercase;
color: rgb(167, 0, 0);
font-weight: 900;
}

li.nav-item {
text-transform: uppercase;
  font-family: 'asu-font-light';
}

.navbar-light .navbar-nav .nav-link {
  color: rgb(0, 0, 0);
}

.py-3 {
  padding-top: 0rem!important;
  padding-bottom: 0rem!important;
}
.py-2 {
  padding-top: 0rem!important;
  padding-bottom: 0rem!important;
}

.justify-content-end {
  justify-content: flex-end!important;
  background-color: #c8c8c8;
  /* width: 400px;
height: 300px;*/
    background-image: linear-gradient(to left, rgba(0,0,0,.5), rgba(0,0,0,0) 38%, rgba(255,255,255,0) 60%, rgba(255,255,255,.5)), linear-gradient(to right, white 42%, #c8c8c8 49%);

}
a.nav-link {
  font-size: 15px !important;
  /* font-weight: 900; */
}
button#dropdown-basic {
   font-weight: 900;
}
.nav-link {
  color: #000000;
  
}
.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  margin-top: 2%;
}
.dropdown-menu {
  background-color: #ffffffb8 !important;
}

/*Top meni*/
a.nav-link.link-dark.px-2 {
  font-size: 12px !important;
  color: black !important;
      padding-top: 11px;
          float: left;
}
input.form-control.me-2{
  height: 25px;
  margin-top: 6px;
  padding: 0rem 0rem;
  color: #212529;
  background-color: #939393;
  border: 1px solid #ffffff;
  border-radius: 0;
}
span#meni {
  /* position: inherit; */
  width: 10px;
  margin-top: 6px !important;
  display: block;
  float: left;
}
header.py-3.mb-4.border-bottom {
  margin-bottom: 0px !important;
}

/*Sekcija prvi dio Video slajd*/
/*Slajder*/
.carousel-item {
  margin-bottom: -5px !important;
  }
.row>* {
  padding-right: 0px !important;
  padding-left: 0px !important;
}
.container.prvaSekcija {
  width: 100% !important;
  max-width: 100%;
  margin-top: 0px;
}
/*Prijavi se dugme*/
button.btn.btn-danger.prijaviSe {
  color: #fff;
  background-color: #c00d0d;
  border-color: #c00d0d;
  min-width: 176px;
  border-radius: 0;
  text-transform: uppercase;
}
/*Sekcija drugi dio */
.container.drugaSekcija {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
      margin-top: 0px;
}

.row.hoverStyle {
  background: url(/media/images/sekcija22.jpeg) no-repeat 50% 0px / cover ;
  background-size: 70% 935px;
  background-position: left;
  opacity: 0.9;
  /*position: absolute;*/
 /* background-size: 100% 935px;*/
 /* width: 100%;
  height: 600px;*/
/*  display: block;*/
  /*max-height: 100%;
  max-width: 100% !important;*/
  margin-top: 0px;
  background-origin: padding-box;
}

.col.drugi {
  content: "";
  /*background: rgba(36, 70, 105, 0.74);*/
 /* position: absolute;*/
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 935px;
   background-image:linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 100%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to right, #ffffff00 21%, #fffffff0 65%) 
}
.card.upisFront  {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 51% !important;
  float: right;
  /* margin-top: 10%; */
  margin-top: 0;
  height: 65%;
  /* margin-right: 12%; */
  /* margin-top: 10%; */
  /* position: absolute; */
  /* padding: 200px; */
  word-wrap: break-word;
  background-color: #ffffff00;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;
}
h1.card-title{
  font-family: asu-font;
}
/*Sekcija treci dio */
.container.trecaSekcija {
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
      margin-top: 0px;
}
.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 100;
  background-color: #fff; /* Postavite boju pozadine prema vašem dizajnu */
  /* Dodajte bilo koji drugi stil koji želite za svoj header */
}

.row.hoverStyleTri {
  background: url(/media/images/sekcija3.jpeg) no-repeat 50% 0px / cover ;
  background-size: 100% 935px;
  background-position: right;
  opacity: 0.9;
  /*position: absolute;*/
 /* width: 100%;
  height: 600px;*/
/*  display: block;*/
/*   max-height: 100%;
  max-width: 100% !important;*/
  margin-top: 0px;
  background-origin: padding-box;
}
.col.treci {
  content: "";
  /*background: rgba(36, 70, 105, 0.74);*/
 /* position: absolute;*/
  top: 0;
  bottom: 0;
  left: 0;
  height: 935px;
  right: 0;
   background-image:linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 0%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to left, #ffffff00 46%, #ffffffe0 63%) 
}
.card.upisFrontTri {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  width: 36% !important;
  float: left;
  margin-top: 10%;
  height: 65%;
  margin-left: 7%;
  /* margin-top: 10%; */
  /* position: absolute; */
  /* padding: 200px; */
  word-wrap: break-word;
  background-color: #ffffff00;
  background-clip: border-box;
  border: none;
  border-radius: 0.25rem;

}
/*Footer*/
.container.footer {
  max-width: 100% !important;
  background-color: #9d9d9c;
  margin: 0px;
  padding-top: 32px;
  /* display: block;
  position: absolute;
  bottom: 0; */
}
.col.footer {
  text-align: center;
  margin-top: 40px !important;
}
.footerCopy {
  background-color: #2d3134;
  color: aliceblue;
  text-align: center;
  padding: 5px;
}
h1.footerNaslov {
  color: white;
  text-align: center;
}
p.footer-info {
  margin-bottom: 0px;
  text-align: center;
  font-family: asu-font-light;
  font-weight: bold !important;
}

/*Odjeci */
main.container.odjseciBox {
  margin: 0;
  max-width: 100%;
  padding: 0;
}

/*card odsjeci*/
h1.card-title.odjseciTitle {
  text-transform: uppercase;
}

.card-body.card-body.odsjekCard{
  padding: 2rem 2rem !important;
  background-image: linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 90%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to left, #ffffff00 -14%, #868686b5 57%)

}
.row.odsjeci {
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: inherit;
}
.card.odsjek {
  width: 33.3%;
  border: 0px;;
}

/*Odjsek detalj*/
.row.hoverStyle.oodsjekDetalj {
  background: url(/media/images/gluma.jpeg) no-repeat 50% 0px / cover;
  background-size: 100% 935px;
  
}
.col.drugi.odsjekDetalj{
background-image: linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 100%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to right, #ffffff00 21%, #ffffff 65%)
}

/*Dekanat*/
.container.drugaSekcija.dekanat {
  background-image: linear-gradient(to left, rgb(255 255 255 / 50%), rgba(0,0,0,0) 15%, rgba(255,255,255,0) 100%, rgba(255,255,255,.5)), linear-gradient(to left, #ffffff00 4%, #97979799 63%)
}
.container.dekanat {
  margin-top: 0px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/*Nastavnik slika*/
img.card-img-top.nastanikSlika {
  width: 90%;
  max-width: 400px;
}
img.card-img-top.slajderSlika {
  width: 100%;
  height: 935px;
}
.container.drugaSekcija.dekanat.nastavnik {
  padding-top: 80px;
  /* margin-top: 100px; */
  padding-bottom: 80px;
}


/*slike nastavnika*/
img.img-fluid.img-thumbnail.profe {
  max-width: 250px !important;
  max-height: 250px !important;
  border: 0px !important;
  border-radius: 0 !important;
  background-color: unset !important;
}
}

/* Stil za hamburger ikonu */
.navbar-toggler-icon {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Stil za glavni meni na širem ekranu */
.navbar-nav {
  margin-left: auto; /* Poravnanje sa desne strane */
}

/* Stil za linkove u meniju na širem ekranu */
.navbar-nav .nav-item {
  margin-right: 15px; /* Razmak između linkova */
}

/* Stil za linkove u meniju na mobilnom ekranu */
.navbar-nav.collapse {
  width: 100%;
  position: absolute;
  top: 60px; /* Visina zaglavlja */
  left: 0;
  background-color: #fff;
  z-index: 1000; /* Visina sloja za prikazivanje iznad ostalog sadržaja */
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Stil za linkove u hamburger meniju */
.navbar-nav.collapse .nav-item {
  margin-right: 0;
  margin-bottom: 10px;
  text-align: center;
}

/* Stil za pretragu na mobilnom ekranu */
.navbar-nav.collapse form {
  margin-top: 10px;
  text-align: center;
}

/* Stil za ikonu pretrage na mobilnom ekranu */
.navbar-nav.collapse form .btn {
  margin-top: 0;
}

/* Stil za responzivni logo */
.navbar-brand img {
  /* max-height: 40px; */
  margin-right: 10px; /* Razmak između loga i teksta */
}

/* Stil za dugme za pretragu na mobilnom ekranu */
.navbar-toggler {
  border: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  font-size: 1.5rem;
}

/* Stil za linkove u glavnom meniju na mobilnom ekranu */
.navbar-nav {
  display: none;
}

/* Stil za linkove u glavnom meniju na mobilnom ekranu kada je meni otvoren */
.navbar-nav.collapse {
  display: block;
}
